export const FaqView = Backbone.View.extend({
  initialize: function () {
    const $faqQuestionBlock = $('.faq-question');
    const $faqQuestionTitle = $('.faq-question--title');
    const $SavoirPlusLink = $('.faq-Information--more');
    const $searchInput = $('.js-faq-searchInput');

    if ($('.faq-Banner').length) {
      $('body').addClass('faq-page');
    }

    $SavoirPlusLink.on('click', (event) => {
      const $this = $(event.target).attr('href');
      this.faqAnchorQuestion($this);
    });

    $(window).on('load', () => {
      let currentUrlHash = window.location.hash;

      if (typeof currentUrlHash != 'undefined' && currentUrlHash != null && currentUrlHash.length) {
        this.faqAnchorQuestion(currentUrlHash);
      }

      this.displayPage();

      if (!$('.faq-container').hasClass('faq-container--Home')) {
        this.addStructuredDatas();
      }

      $('.js-faq-linkToCategory').on('click', (event) => {
        event.preventDefault();
        const $item = $(event.target).hasClass('faq-allCategoriesLink') ? $(event.target) : $(event.target).closest('.faq-allCategoriesLink');
        const $allCategoriesList = $('.faq-allCategoriesList');

        if (!$(event.target).parents('.faq-allCategoriesList').hasClass('faq-allCategoriesList--withQuestion')) {
          Tracking.dataLayerPush({
            clickCode: 'clickQuestionFaq',
            gaCategory: PAGE_SCRIPTS.page_template,
            gaAction: $item.data('label'),
            gaLabel: 'click',
            event: 'clickEvent'
          });
        } else {
          const isSearchResult = window.location.href.split('search')[1] !== undefined;

          Tracking.dataLayerPush({
            userConsent: false,
            environment: GLOBAL_SCRIPTS.ENV,
            currency: GLOBAL_SCRIPTS.CURRENCY_CODE,
            language: GLOBAL_SCRIPTS.LANGUAGE_CODE,
            siteCountry: GLOBAL_SCRIPTS.MARKET_CODE,
            pageCategoryLevel1: isSearchResult ? 'FAQ Search' : 'FAQ Category',
            pageTemplate: PAGE_SCRIPTS.page_template,
            pageName: '/FAQ/' + $item.data('label'),
            page_shrturl: GLOBAL_SCRIPTS.MARKET_LANGUAGE + '/FAQ/' + $item.data('label'),
            event: 'clickEvent',
            clickCode: 'clickFaqPageview'
          });
        }

        $('.faq-searchQuestionsAnswers').removeClass('faq-searchQuestionsAnswers--open');
        $('.faq-searchQuestionsAnswers .faq-question, .button--suggestions').remove();
        $('.faqText').removeClass('hidden');
        $('.faq-container').removeClass('faq-container--Home');

        if ($item.hasClass('active') && !DEVICE.isDesktop) {
          $(event.target).parents('.faq-allCategoriesLink').removeClass('active');
          $(event.target).removeClass('active');
        } else {
          if ($item.next('.faqText').length === 0) {
            this.getCategory(event);
          } else {
            const url = GLOBAL_SCRIPTS.BASELINK + '/faq/' + $item.data('slug');

            window.history.pushState({path: url}, '', url);

            $('.faq-allCategoriesLink').removeClass('active');
            $('.faq-topQuestions').addClass('hidden');
            $item.addClass('active');
            $allCategoriesList.addClass('faq-allCategoriesList--withQuestion');

            $('.js-faq-linkToCategory').on('click', () => {
              this.faqUpdateHeight();
            });
          }
        }

        if ($allCategoriesList.length && $allCategoriesList.hasClass('faq-allCategoriesList--withQuestion')) {
          if ((DEVICE.isMobile || DEVICE.isTablet) && $('.faq-allCategoriesLink.active').length) {
            $('html, body').animate({
              scrollTop: DEVICE.isMobile || DEVICE.isTablet ? $('.faq-allCategoriesLink.active').offset().top : $allCategoriesList.offset().top - (parseInt($('.faq-Information').css('margin-bottom')) / 2)
            }, 'slow');
          }
        }

        this.faqUpdateHeight();
      });
    });

    $(window).on('popstate', () => {
      this.displayPage();
      this.faqUpdateHeight();
    });

    $faqQuestionTitle.on('click', (event) => {
      const $this = $(event.target);
      const url = GLOBAL_SCRIPTS.BASELINK + '/faq/' + $this[0].dataset.slug;
      const item = $this.hasClass('faq-searchQuestionLink') ? $this : $this.parents('.faq-searchQuestionLink');
      const index = $('.faq-question').index(item) + 1;

      window.history.pushState({path: url}, '', url);

      $this.closest($faqQuestionBlock).toggleClass('active');

      if ($this.hasClass('faq-questionSearch')) {
        Tracking.dataLayerPush({
          clickCode: 'FaqSuggestionClick',
          gaCategory: PAGE_SCRIPTS.page_template,
          gaAction: 'FAQ Question Click',
          gaLabel: 'pos' + (index) + ' - ' + $this.text(),
          gaValue: index,
          freesearch: striptags($('.faq-searchInput').val()),
          event: 'clickEvent'
        });
      }

      if (DEVICE.isDesktop) {
        this.faqUpdateHeight();
      }
    });

    //handling specific active block on desktop
    if (DEVICE.isDesktop) {
      this.faqUpdateHeight();
    }

    /* SEARCH ENGINE EVENT */
    $('.js-faq-searchSubmit').on('click', (event) => {
      this.searchSubmit(event);
    });

    $searchInput.on('focus', () => {
      this.searchFocus();
    });

    $searchInput.on('keyup', (event) => {
      this.searchKeyup(event);
    });

    $searchInput.on('blur', () => {
      $('.faq-searchContainer').remove('searchFaqOpened');
    });

    $('body .faq-content, body .faq-BannerImage, .header, .h2-like').on('click', () => {
      this.closeAutocomplete();
    });

    this.toggleTopQuestion();

    $('.js-back-home').off('click').on('click', (event) => {
      this.backHome(event);
      this.getCategory(event);
      this.toggleTopQuestion();
    });
  },

  searchSubmit(event) {
    event.preventDefault();
    if ($('.faq-searchInput').val().length > 2) {
      this.getQuestionsSearch(event);

      Tracking.dataLayerPush({
        clickCode: 'clickFaqSearch',
        gaCategory: PAGE_SCRIPTS.page_template,
        gaAction: 'FAQ Search',
        gaLabel: 'rechercher',
        freesearch: striptags($('.faq-searchInput').val()),
        event: 'clickEvent'
      });
    }
  },

  searchKeyup(event) {
    const $searchClose = $('.faq-searchInputReset');
    const nbCharacter = event.target.value.length;
    const $searchQuestion = $('.faq-searchQuestion');

    if (!$searchClose.length && nbCharacter > 0) {
      this.addResetSearchValue();
    }

    if (nbCharacter > 2 && (event.key === 'Enter' || event.keyCode === 13)) {
      this.getQuestionsSearch(event);
      $('.js-faq-searchInput').blur();
      this.closeAutocomplete();
      $('.faq-allCategoriesLink').removeClass('active');
      $searchQuestion.remove();
    } else {
      if (!$('.js-faq-searchSubmit').hasClass('disabled')) {
        $('.js-faq-searchSubmit').addClass('disabled');
      }

      if (nbCharacter > 2) {
        $('.js-faq-searchSubmit').removeClass('disabled');
        $('.faq-searchQuestion--first').remove();
        this.getQuestionsOnAutocomplete();
      } else if (nbCharacter === 0) {
        this.closeAutocomplete();
      } else {
        $searchQuestion.remove();
        if ($('.faq-searchQuestion--first').length === 0) {
          $('.faq-searchContainer').append('<div class="faq-searchQuestion faq-searchQuestion--first">' + FAQ_SCRIPTS.WORDINGS_FAQ.fill_3_car + '</div>');
        }
      }
    }
  },

  searchFocus() {
    const $searchContainer = $('.faq-searchContainer');
    const $searchQuestionFirst = $('.faq-searchQuestion--first');
    const nbCharacter = $('.js-faq-searchInput').val().length;

    $searchContainer.addClass('searchFaqOpened');

    if ($searchQuestionFirst.length === 0) {
      $searchContainer.append('<div class="faq-searchQuestion faq-searchQuestion--first">' + FAQ_SCRIPTS.WORDINGS_FAQ.fill_3_car + '</div>');
    }

    if (nbCharacter > 2) {
      $searchQuestionFirst.remove();
      $('.js-faq-searchSubmit').removeClass('disabled');
      this.getQuestionsOnAutocomplete();
    } else {
      $('.faq-searchQuestion').remove();
    }

    if (!$('.faq-searchInputReset').length && nbCharacter > 0) {
      this.addResetSearchValue();
    }
  },

  getCategory(event) {
    event.preventDefault();
    const $this = $(event.target).hasClass('faq-allCategoriesItem') ? $(event.target) : $(event.target).find('.faq-allCategoriesItem');
    const url = $this.data('url') ? $this.data('url') : $this.parent().data('url') ? $this.parent().data('url') : GLOBAL_SCRIPTS.BASELINK + '/faq/';
    const idCategory = $this.data('id') !== undefined ? $this.data('id') : $this.parents('.faq-allCategoriesLink').data('id') ? $this.parents('.faq-allCategoriesLink').data('id') : '';
    const categoryLabel = $this.text();
    const $linkCategory = $this.parent('.faq-allCategoriesLink');
    const ajaxUrl = idCategory !== '' ? `${FAQ_SCRIPTS.URL_API_FAQ}/v1/category/${GLOBAL_SCRIPTS.UNIVERS}/${GLOBAL_SCRIPTS.LANGUAGE_CODE}/${idCategory}` : `${FAQ_SCRIPTS.URL_API_FAQ}/v1/categories/${GLOBAL_SCRIPTS.UNIVERS}/${GLOBAL_SCRIPTS.LANGUAGE_CODE}`;

    const successCallback = (response) => {
      if (response.status === 'success') {
        const questions = response.data.questions;

        if (event.type === 'click' && idCategory !== '') {
          $('.faqText, .faq-allCategoriesLink').removeClass('active');
          $('.faq-topQuestions, .faq-allCategoriesTitle').addClass('hidden');
          $linkCategory.addClass('active');
          $('.faq-allCategoriesList').addClass('faq-allCategoriesList--withQuestion');
          window.history.pushState({path: url}, '', url);
          $('.faq-allCategoriesLink[data-id=' + idCategory + ']').after('<div class="faqText api_accordion-itemContent active"></div>');
          $('.faq-allCategoriesLink[data-id=' + idCategory + '] + .faqText').append('<h1 class="faqTitle">' + categoryLabel + '</h1><p class="faq-lastUpdate">' + FAQ_SCRIPTS.WORDINGS_FAQ.faq_last_update + '</p>');

          $.each(questions, (i, item) => {
            const urlQuestion = GLOBAL_SCRIPTS.BASELINK + '/faq/' + item.slug;
            const labelQuestion = item.label;
            const idQuestion = item.id;
            const idCategory = item.categoryId;
            const anchor = item.anchor;
            const labelAnswer = item.answer.label;
            const additionalClass = item.selected ? ' active' : '';
            const buttonUrl = item.url;
            const buttonLabel = item.urlText;
            const buttonTitle = item.urlTitle;
            let divQuestions = `
              <div class="faq-question faq-question${i}${additionalClass}" data-url="${urlQuestion}" data-idcategory="${idCategory}" >
                <div class="js-faq-question faq-question--block">
                  <h2 class="faq-question--title" data-anchor="${anchor}"  data-idquestion="${idQuestion}" data-idcategory="${idCategory}" data-url="${urlQuestion}">${labelQuestion}</h2>
                </div>
                <div class="faq-answer">
                  <p>${labelAnswer}</p>`;
            if (buttonUrl !== undefined) {
              divQuestions += `
                  <a href="${buttonUrl}" class="faq-searchButton button button--transparentBlack" title="${buttonTitle}">${buttonLabel}</a>`;
            }
            divQuestions += `
                </div>
              </div>`;
            $('.faq-allCategoriesLink.active + .faqText').append(divQuestions);
            if (idCategory !== '') {
              $('.faq-question' + i + '[data-idcategory=' + idCategory + '] .js-faq-question').on('click', () => {
                $('.faq-question' + i + '[data-idcategory=' + idCategory + ']').toggleClass('active');
                this.faqUpdateHeight();
                window.history.pushState({path: urlQuestion}, '', urlQuestion);
              });
            }
          });
          $('html, body').animate({
            scrollTop: DEVICE.isMobile || DEVICE.isTablet ? $('.faq-allCategoriesLink.active').offset().top : $('.faq-allCategoriesList').offset().top - (parseInt($('.faq-Information').css('margin-bottom')) / 2)
          }, 'slow');
        } else if (!$('.faq-searchTopQuestion').length) {
          $.each(questions, (i, item) => {
            if (i < 5) {
              const urlQuestion = GLOBAL_SCRIPTS.BASELINK + '/faq/' + item.slug;
              const labelQuestion = item.label;
              const idQuestion = item.id;
              const idCategory = item.categoryId;
              const divQuestions = `
                      <div class="faq-searchTopQuestion">
                        <a href="${urlQuestion}" class="js-faq-topQuestion faq-topQuestions-link faq-topQuestions-item" data-url="${urlQuestion}" data-idquestion="${idQuestion}" data-idcategory="${idCategory}">
                          ${labelQuestion}
                        </a>
                      </div>`;
              $('.faq-topQuestions-list').append(divQuestions);
            }
          });
          $('.faq-topQuestions-title').html(FAQ_SCRIPTS.WORDINGS_FAQ.faq_top_questions);
          $('.faq-topQuestions').removeClass('hidden');
          $('.faq-allCategoriesList').removeClass('faq-allCategoriesList--withQuestion');
        }
        this.faqUpdateHeight();
      }
    };
    genericAjaxCall({
      ajaxUrl: ajaxUrl,
      successCallback: successCallback,
      dataType: 'json',
      type: 'GET'
    });
  },

  /* HOME FAQ */
  toggleTopQuestion() {
    $('.js-toggleQuestions').off('click').on('click', () => {
      $('.faq-topQuestions-title, .faq-topQuestions').toggleClass('open');

      Tracking.dataLayerPush({
        clickCode: 'clickQuestionFaq',
        gaCategory: PAGE_SCRIPTS.page_template,
        gaAction: 'Les tops questions du moment',
        gaLabel: 'click',
        event: 'clickEvent'
      });
    });

    $('.js-faq-topQuestion').on('click', (event) => {
      event.preventDefault();
      const $item = $(event.target);
      const idCategory = $item.data('idcategory');
      const idQuestionSelected = $item.data('idquestion');

      $('.faq-container').removeClass('faq-container--Home');
      $('.faq-allCategoriesLink[data-id=' + idCategory + ']').addClass('active');

      const successCallback = (response) => {
        const url = GLOBAL_SCRIPTS.BASELINK + '/faq/' + $item.data('url');
        window.history.pushState({path: url}, '', url);

        $('.faq-topQuestions-title, .faq-topQuestions').removeClass('open');
        $('.faq-topQuestions').css('max-height', '').addClass('hidden');
        $('.faq-allCategoriesTitle, .faqTitleSearch, .faq-searchSubtitle').addClass('hidden');

        if (response.status === 'success') {
          const questions = response.data.questions;

          $('.faq-allCategoriesLink').removeClass('active');
          $('.faq-allCategoriesLink[data-id=' + idCategory + ']').after('<div class="faqText active"></div>').addClass('active');
          $('.faq-allCategoriesLink[data-id=' + idCategory + '] + .faqText').append('<span class="faqTitle">' + $('.faq-allCategoriesLink[data-id=' + idCategory + '] .faq-allCategoriesItem').html() + '</span>');

          $(questions).each((i, item) => {
            const urlQuestion = GLOBAL_SCRIPTS.BASELINK + '/faq/' + item.url;
            const labelQuestion = item.label;
            const idQuestion = item.id;
            const idCategory = item.category.id;
            const anchor = item.anchor;
            const labelAnswer = item.answer.label;
            const buttonUrl = item.url;
            const buttonLabel = item.urlText;
            const buttonTitle = item.urlTitle;
            const additionalClass = idQuestionSelected === parseInt(item.id) ? ' active' : '';
            let divQuestions = `
              <div class="faq-question faq-question${i}${additionalClass}">
                <div class="js-faq-question faq-question--block">
                  <h2 class="faq-question--title" data-anchor="${anchor}" data-idquestion="${idQuestion}" data-idcategory="${idCategory}" data-url="${urlQuestion}">${labelQuestion}</h2>
                </div>
                <div class="faq-answer">
                  <p>${labelAnswer}</p>`;
            if (buttonUrl !== undefined) {
              divQuestions += `
                  <a href="${buttonUrl}" class="faq-searchButton button button--transparentBlack" title="${buttonTitle}">${buttonLabel}</a>`;
            }
            divQuestions += `
                </div>
              </div>`;
            $('.faq-allCategoriesLink[data-id=' + idCategory + '] + .faqText').append(divQuestions);
            $('.faq-allCategoriesList').addClass('faq-allCategoriesList--withQuestion');
            $('.faq-question' + i).on('click', () => {
              $('.faq-question' + i).toggleClass('active');
              this.faqUpdateHeight();
            });
          });
          $('html, body').animate({
            scrollTop: $('.faq-question.active').offset().top - 20
          }, 'slow');
        }
        this.faqUpdateHeight();
      };
      genericAjaxCall({
        ajaxUrl: `${FAQ_SCRIPTS.URL_API_FAQ}/v1/category/${GLOBAL_SCRIPTS.UNIVERS}/${GLOBAL_SCRIPTS.LANGUAGE_CODE}/${idCategory}`,
        successCallback,
        dataType: 'json',
        type: 'GET'
      });
    });
  },

  /* SEARCH ENGINE */
  getOneQuestion(event) {
    event.preventDefault();
    const $this = $(event.target);
    const $item = $this.hasClass('faq-searchQuestionLink') ? $this : $this.parents('.faq-searchQuestionLink');
    const question = $item.html();
    const url = $item.data('url');
    const idQuestion = $item.data('id');
    const $searchAnswer = $item.siblings('.faq-searchAnswerContainer');
    const answers = $searchAnswer[0] ? $searchAnswer[0].outerHTML : '';
    const containerQuestionAnswer = `
      <div class="faq-question active">
        <div class="js-faq-question faq-question--block">
          <h2 class="faq-question--title">${question}</h2>
        </div>
        <div class="faq-answer" data-id="${idQuestion}" data-url="${url}">${answers}</div>
      </div>`;

    $('.faq-searchQuestionsAnswers .faq-question').remove();
    $('.faq-searchSubtitle').after(containerQuestionAnswer);
    $('.faq-allCategoriesLink, .faqText').removeClass('active');

    if (!DEVICE.isDesktop) {
      $('.faq-topQuestions--container, .faq-allCategoriesTitle, .faq-allCategoriesList').addClass('hidden');
    }

    $('.faqTitleSearch').removeClass('hidden').html(FAQ_SCRIPTS.WORDINGS_FAQ.faq_search_title);

    $('.faq-searchQuestionsAnswers .js-faq-question').on('click', () => {
      $('.faq-question').toggleClass('active');
      this.faqUpdateHeight();
      window.history.pushState({path: url}, '', url);
    });

    $('.faq-topQuestions--container, .faq-allCategoriesTitle, .faqText, .faq-searchSubtitle, .faq-searchSubtitle + .button').addClass('hidden');
    $('.faq-allCategoriesList').addClass('faq-allCategoriesList--withQuestion');
    $('.faq-searchQuestionsAnswers').addClass('faq-searchQuestionsAnswers--open');
    $('.js-faq-searchSubmit').removeClass('buttonLoader');
    $('.faq-searchQuestionSuggestion').remove();
  },

  getQuestionsSearch(event) {
    event.preventDefault();
    const value = striptags($('.js-faq-searchInput').val());
    const url = GLOBAL_SCRIPTS.BASELINK + '/faq/search?query=' + value;

    window.history.pushState({path: url}, '', url);

    $('.faq-searchQuestion').remove();
    $('.faq-topQuestions--container, .faq-allCategoriesTitle').addClass('hidden');
    $('.faq-allCategoriesList').addClass('faq-allCategoriesList--withQuestion');
    $('.faq-allCategoriesLink').removeClass('active');
    $('.faqTitleSearch, .faq-searchSubtitle').removeClass('hidden');

    const successCallback = (response) => {
      if (response.status === 'success') {
        const $faqTitleSearch = $('.faqTitleSearch');
        const $searchSubtitle = $('.faq-searchSubtitle');
        const data = response.data;
        const questions = data.questions;

        $faqTitleSearch.text(value);

        if (questions.length > 0) {
          $('.button--suggestions').remove();
          $searchSubtitle.html(questions.length === 1 ? questions.length + ' ' + FAQ_SCRIPTS.WORDINGS_FAQ.results_singular : questions.length + ' ' + FAQ_SCRIPTS.WORDINGS_FAQ.results_plural);
        } else {
          $searchSubtitle.html(FAQ_SCRIPTS.WORDINGS_FAQ.no_results);

          if ($('.button--suggestions').length === 0) {
            $searchSubtitle.after('<span class="button button--greenWhite button--suggestions js-back-home">' + FAQ_SCRIPTS.WORDINGS_FAQ.see_mostly_asked + '</span>');
            $('.js-back-home').off('click').on('click', (ev) => {
              this.backHome(ev);
              this.getCategory(ev);
              this.toggleTopQuestion();
            });
          }
        }

        $('.faq-searchQuestionsAnswers .faq-question').remove();

        if (questions.length > 0) {
          $.each(questions, (i, item) => {
            const question = {
              questionPosition: i + 1,
              questionText: item.question.label,
              questionCategory: item.category.label,
              buttonUrl: item.question.url,
              buttonTitle: item.question.urlTitle,
              buttonLabel: item.question.urlText
            };
            let containerQuestionAsnwer = `
              <div class="faq-question faq-question${i}">
                <div class="js-faq-question faq-question--block ">
                  <h2 class="faq-question--title faq-questionSearch" data-url="${item.question.slug}">${item.question.label}</h2>
                </div>
                <div class="faq-answer" data-url="${item.question.slug}">
                  <p>${item.question.answer.label}</p>
                `;

            if (question.buttonUrl !== undefined) {
              containerQuestionAsnwer += `
                  <a href="${question.buttonUrl}" class="faq-searchButton button button--transparentBlack" title="${question.buttonTitle}">${question.buttonLabel}</a>`;
            }

            containerQuestionAsnwer += `
                </div>
              </div>`;

            $('.faq-searchSubtitle').before(containerQuestionAsnwer);
            $('.faq-searchQuestionsAnswers .faq-question' + i).on('click', (ev) => {

              $('.faq-question' + i).toggleClass('active');

              if ($(ev.target).hasClass('faq-questionSearch')) {
                Tracking.dataLayerPush({
                  clickCode: 'FaqSuggestionClick',
                  gaCategory: PAGE_SCRIPTS.page_template,
                  gaAction: 'FAQ Question Click',
                  gaLabel: 'pos' + (i + 1) + ' - ' + item.question.label,
                  gaValue: i + 1,
                  freesearch: striptags($('.faq-searchInput').val()),
                  event: 'clickEvent'
                });
              }
            });
          });

          $('.faqTitleSearch').after($('.faq-searchSubtitle'));
          $('.faq-question + .faq-searchSubtitle').remove();
        }

        if (DEVICE.isMobile || DEVICE.isTablet) {
          $('.faq-allCategoriesList').addClass('hidden');
        }

        $('.faq-searchQuestionsAnswers').addClass('faq-searchQuestionsAnswers--open');
        $('.js-faq-searchSubmit').removeClass('buttonLoader');
        $('.faq-searchQuestionSuggestion').remove();

        Tracking.dataLayerPush({
          event: 'search_results_displayed',
          sub_event: 'FAQ Search',
          gaCategory: PAGE_SCRIPTS.page_template,
          gaLabel: value,
          list_results_number: questions.length
        });
      }
      this.faqUpdateHeight();
    };

    genericAjaxCall({
      ajaxUrl: `${FAQ_SCRIPTS.URL_API_FAQ}/v1/search/${GLOBAL_SCRIPTS.UNIVERS}/${GLOBAL_SCRIPTS.LANGUAGE_CODE}?query=${encodeURIComponent(value)}`,
      successCallback,
      dataType: 'json',
      type: 'GET'
    });

  },

  closeAutocomplete() {
    $('.faq-searchContainer').removeClass('searchFaqOpened');
    $('.faq-searchInputReset, .faq-searchQuestion, .faq-searchContainer .faq-searchQuestion').remove();
    $('.js-faq-searchInput').blur();
  },

  addResetSearchValue() {
    $('.faq-searchContainer').prepend('<div class="faq-searchInputReset"></div>');

    $('.faq-searchInputReset').css('right', $('.js-faq-searchSubmit').width() + 20).on('click', () => {
      $('.faq-searchInput').val('');
      this.closeAutocomplete();
      $('.js-faq-searchInput').focus();
      $('.faq-searchContainer').remove('searchFaqOpened');

      Tracking.dataLayerPush({
        clickCode: 'clickFaqSuggestion',
        gaCategory: PAGE_SCRIPTS.page_template,
        gaAction: 'FAQ Search',
        gaLabel: 'Reset search',
        event: 'clickEvent'
      });
    });
  },

  getQuestionsOnAutocomplete() {
    const $searchContainer = $('.faq-searchContainer');
    const $searchInput = $('.js-faq-searchInput');
    const value = striptags($searchInput.val());

    const successCallback = (response) => {
      if (response.status === 'success') {
        const data = response.data;
        const questions = data.questions;

        $('.faq-searchQuestion').remove();
        $('.faq-searchQuestionSuggestion').remove();

        $.each(questions, (i, item) => {
          const urlQuestion = GLOBAL_SCRIPTS.BASELINK + '/faq/' + item.slug;
          const label = item.label;
          const categoryId = item.categoryId;
          const categoryUrl = item.slug.substr(0, item.slug.indexOf('#'));
          const buttonUrl = item.url;
          const buttonTitle = item.urlTitle ? item.urlTitle : '';
          const buttonLabel = item.urlText ? item.urlText : '';
          let divQuestions = `
            <div class="faq-searchQuestion">
              <a href="#" data-id="${item.id}" data-categoryid="${categoryId}" data-categoryurl="${categoryUrl}"  data-url="${urlQuestion}" class="faq-searchQuestionLink js-faq-searchQuestion">${label}</a>
              <div class="faq-searchAnswerContainer">
                <div class="faq-searchAnswer">
                  <p>${item.answer.label}</p>`;
          if (buttonUrl !== undefined) {
            divQuestions += `<a href="${buttonUrl}" class="faq-searchButton button button--transparentBlack" title="${buttonTitle}">${buttonLabel}</a>`;
          }
          divQuestions += `
                </div>
              </div>
            </div>`;
          $searchContainer.append(divQuestions);

          Tracking.dataLayerPush({
            clickCode: 'FaqSuggestionImpression',
            gaCategory: PAGE_SCRIPTS.page_template,
            gaAction: 'FAQ-Suggestion Impression',
            gaLabel: 'pos' + (i + 1) + ' - ' + item.label,
            gaValue: i + 1,
            freesearchQuery: striptags($('.faq-searchInput').val()),
            event: 'clickEvent'
          });
        });

        if (questions.length > 0) {
          if (data.viewMore) {
            const divSuggestions = '<div class="faq-searchQuestion faq-searchQuestionSuggestion js-faq-searchQuestionSuggestion"><a href="#">' + FAQ_SCRIPTS.WORDINGS_FAQ.see_suggestions + '</a></div>';
            $searchContainer.append(divSuggestions);
          }

          $('.js-faq-searchQuestionSuggestion').on('click', (event) => {
            event.preventDefault();
            this.getQuestionsSearch(event);

            Tracking.dataLayerPush({
              clickCode: 'clickFaqSuggestion',
              gaCategory: PAGE_SCRIPTS.page_template,
              gaAction: 'FAQ Search',
              gaLabel: 'Voir toutes les suggestions',
              freesearchQuery: striptags($('.faq-searchInput').val()),
              event: 'clickEvent'
            });
          });
        } else if (questions.length === 0) {
          const div = '<div class="js-faq-searchSubmit faq-searchQuestion faq-searchQuestionNoAsnwser">' + FAQ_SCRIPTS.WORDINGS_FAQ.no_results + '</div>';
          $searchContainer.append(div);
        }

        $('.faq-searchInputReset').on('click', () => {
          $('.faq-searchQuestion:not(.faq-searchQuestion--first)').remove();
        });

        $('.js-faq-searchQuestion').on('click', (event) => {
          event.preventDefault();
          const $this = $(event.target);
          const item = $this.hasClass('faq-searchQuestionLink') ? $this : $this.parents('.faq-searchQuestionLink');
          const index = $('.faq-searchQuestionLink').index(item) + 1;

          Tracking.dataLayerPush({
            clickCode: 'FaqSuggestionClick',
            gaCategory: PAGE_SCRIPTS.page_template,
            gaAction: 'FAQ Suggestion Click',
            gaLabel: 'pos' + index + ' - ' + item.text(),
            gaValue: index,
            freesearchQuery: striptags($('.faq-searchInput').val()),
            event: 'clickEvent'
          });
          this.closeAutocomplete();
          this.getOneQuestion(event);
        });
      }
    };

    genericAjaxCall({
      ajaxUrl: `${FAQ_SCRIPTS.URL_API_FAQ}/v1/autocomplete/${GLOBAL_SCRIPTS.UNIVERS}/${GLOBAL_SCRIPTS.LANGUAGE_CODE}?query=${encodeURIComponent(value)}`,
      successCallback,
      dataType: 'json',
      type: 'GET'
    });
  },

  /* BACK HOME FROM SEARCH */
  backHome(event) {
    const $this = $(event.target);
    const isNormalBack = $this.hasClass('faqTitleBackHome');
    const url = GLOBAL_SCRIPTS.BASELINK + '/faq';

    window.history.pushState({path: url}, '', url);

    $('.faq-container').addClass('faq-container--Home');
    $('.faq-topQuestions--container, .faq-topQuestions, .faq-allCategoriesTitle, .faq-allCategoriesList').removeClass('hidden');
    $('.faq-allCategoriesLink, .faqText').removeClass('active');
    $('.faq-allCategoriesList').removeClass('faq-allCategoriesList--withQuestion');
    $('.faq-searchQuestionsAnswers').removeClass('faq-searchQuestionsAnswers--open');
    $('html, body').animate({scrollTop: 0}, 'slow');

    Tracking.dataLayerPush({
      clickCode: isNormalBack ? 'clickFaqRetour' : 'clickFaqSeeQuestionsCTA',
      gaCategory: PAGE_SCRIPTS.page_template,
      gaAction: 'FAQ Search',
      gaLabel: isNormalBack ? 'Retour' : 'Voir les questions fréquentes',
      event: 'clickEvent',
      freesearch: isNormalBack ? '' : $('.faqTitleSearch').text()
    });
  },

  /* JSON DATAS*/
  addStructuredDatas() {
    const structuredDataText = {
      '@context': 'https://schema.org',
      '@type': 'FAQPage',
      'mainEntity': []
    };

    $('.faq-question').each((i, item) => {
      const $item = $(item);

      structuredDataText.mainEntity.push({
        '@type': 'Question',
        'name': $item.find('.faq-question--title').text(),
        'acceptedAnswer': {
          '@type': 'Answer',
          'text': $item.find('.faq-answer').text().replace(/\n/g, '').replace(/"/g, '').trim()
        }
      });
    });
    $('head').append('<script type="application/ld+json">' + JSON.stringify(structuredDataText) + '</script>');
  },

  /* POPSTATE */
  displayPage() {
    const url = window.location.href;
    const isSearchResult = url.split('search')[1] !== undefined;
    const isHome = url.substr(url.length - 3) === 'faq' || url.substr(url.length - 4) === 'faq/';
    const anchor = url.split('#')[1];
    const categoryId = url.substring(url.indexOf('faq/') + 4, url.lastIndexOf('_'));
    const category = url.split('faq/')[1] !== undefined ? url.substring(url.indexOf('faq/') + 4, anchor === undefined ? url.length : url.lastIndexOf('#')) : '';
    const $faqQuestionBlock = $('.faq-question');

    // CASE HOME
    if (isHome) {
      this.getCategory(event);
      $('.faq-container').addClass('faq-container--Home');
      $('.faq-allCategoriesList').removeClass('faq-allCategoriesList--withQuestion');
      $('.faq-topQuestions--container, .faq-topQuestions').removeClass('hidden');
      $('.faq-searchQuestionsAnswers').removeClass('faq-searchQuestionsAnswers--open');
      $('.faq-searchQuestionsAnswers .faq-question, .button--suggestions').remove();
      $('html, body').animate({scrollTop: 0}, 'slow');
    }

    // CASE SEARCH
    if (isSearchResult) {
      const keyword = striptags(url.split('?query=')[1]);

      if (!DEVICE.isDesktop) {
        $('.faq-topQuestions--container, .faq-allCategoriesTitle, .faq-allCategoriesList').addClass('hidden');
      }

      $('.faq-searchInput').val(keyword);
      $('.faqTitleSearch').text(keyword);
      this.getQuestionsSearch(event);
    }

    // CASE ANCHOR URL
    else if (!isSearchResult && anchor !== undefined) {
      $('.faq-allCategoriesLink, .faqText').removeClass('active');
      $('.faq-allCategoriesLink[data-id=' + categoryId + ']').addClass('active');

      $.map($faqQuestionBlock, (item) => {
        const $item = $(item);
        const faqQuestionTitle = $item.find('.faq-question--title');

        if ($(faqQuestionTitle).data('anchor') === anchor) {
          $item.addClass('active');
          $('html, body').animate({
            scrollTop: DEVICE.isTablet ? $item.offset().top - 10 : $item.offset().top - parseInt($item.find('.faq-question--title').css('padding-top'))
          }, 'slow');
        } else {
          $item.closest($faqQuestionBlock).removeClass('active');
        }
      });

      this.faqUpdateHeight();
    }

    // CASE CATEGORY
    else if (!isSearchResult && category !== '') {
      $('.faq-allCategoriesLink, .faqText').removeClass('active');
      $('.faq-allCategoriesLink[data-id=' + categoryId + ']').addClass('active');
      $('.faq-allCategoriesList').addClass('faq-allCategoriesList--withQuestion');
      $('html, body').animate({
        scrollTop: DEVICE.isMobile || DEVICE.isTablet ? $('.faq-allCategoriesLink.active').offset().top : $('.faq-allCategoriesList').offset().top - (parseInt($('.faq-Information').css('margin-bottom')) / 2)
      }, 'slow');
      this.faqUpdateHeight();
    }
  },

  /* LOAD CATEGORY PAGE */
  faqAnchorQuestion(param) {
    const $faqTabs = $('.faqTabs');
    const $faqText = $('.faqText');
    let QuestionAnchor = param.substring(1).split('_');

    for (let i = 0; i < $faqTabs.length; i++) {
      if (parseInt($faqTabs[i].getAttribute('data-index')) === parseInt(QuestionAnchor[0])) {
        $faqTabs[i].classList.add('active');
        $faqText.removeClass('active');

        for (let j = 0; j < $faqText.length; j++) {
          if (parseInt($faqText[j].getAttribute('data-index')) === parseInt(QuestionAnchor[0])) {
            const isSafariOs14 = DEVICE.navigator.isSafari && DEVICE.navigator.getVersion().version === 14;
            let QuestionClass = $faqText[j].querySelector('#' + QuestionAnchor[1]);

            $faqText[j].classList.add('active');
            QuestionClass.classList.add('active');

            // -- manual fix popin cookie didomi
            const $bodyDidomiPopupOpenIos = $('body.didomi-popup-open-ios');

            if ($bodyDidomiPopupOpenIos.length) {
              $bodyDidomiPopupOpenIos.attr('style', isSafariOs14 ? 'overflow: auto !important; position: static !important' : 'style', 'position: static !important');
            }

            $('html, body').animate({scrollTop: ($(QuestionClass).offset().top)}, 500, 'swing', () => {
              if (isSafariOs14) {
                $bodyDidomiPopupOpenIos.attr('style', 'position: static !important');
              }
            });
            break;
          }
        }
        break;
      }
    }

    if (DEVICE.isDesktop) {
      this.faqUpdateHeight();
    }
  },

  /* Update page container on desktop */
  faqUpdateHeight() {
    const divContent = $('.faq-allCategoriesLink.active').length ? $('.faq-allCategoriesLink.active + .faqText').height() : $('.faq-searchQuestionsAnswers').height();
    const divMenu = $('.faq-allCategoriesList').height();
    const faqHeightContainer = divContent > divMenu ? divContent : divMenu;

    $('.faq-container').css('min-height', faqHeightContainer);
  }
});

$(() => {
  new FaqView();
});